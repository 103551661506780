import React, { useLayoutEffect, useRef } from 'react';
import { graphql } from 'gatsby';
import useDefaultTheme from './../hooks/useDefaultTheme';

import Seo from '../components/Seo';
import Blocks from '../components/Blocks';
import logo from '../images/logo.svg';

import right from '../images/bg-right.svg';
import top from '../images/bg-top.svg';
import bottom from '../images/bg-bottom.svg';

export default function IndexPage({ data }) {
	const page = data && data.page;
	const el = useRef(null);
	useDefaultTheme('orange');
	const bg = el.current;

	useLayoutEffect(() => {
		document.addEventListener('mousemove', function (e) {
			if (el && el.current) {
				el.current.childNodes.forEach((layer) => {
					const speed = layer.getAttribute('data-speed');

					const x = (window.innerWidth - e.pageX * speed) / 100;
					const y = (window.innerWidth - e.pageY * speed) / 100;

					layer.style.transform =
						'translateX(' +
						x +
						'px) translateY(' +
						y +
						'px) scale(110%)';
				});
			}
		});
	});

	if (!page) return;
	return (
		<>
			<Seo
				metaTitle={page.seo?.metaTitle || page.title}
				shareTitle={page.seo?.shareTitle || page.title}
				metaDesc={page.seo?.metaDesc}
				shareDesc={page.seo?.shareDesc}
			/>
			<h1 className="hidden">{page.title}</h1>
			<section
				ref={el}
				className="relative overflow-hidden py-8 md:py-16 h-screen-mb lg:h-screen-dt w-full mx-auto px-4 flex justify-center items-center flex-1"
			>
				<img
					src={right}
					alt=""
					className="bg bg--right"
					data-speed="15"
				/>
				<img src={top} alt="" className="bg bg--top" data-speed="10" />
				<img
					src={bottom}
					alt=""
					className="bg bg--bottom"
					data-speed="5"
				/>
				<img
					src={logo}
					className="pointer-events-none h-40 md:h-56"
					alt="Bocca Italian"
				/>
			</section>
			<main className="space-y-4vw">
				<Blocks blocks={page.blocks} _rawBlocks={page._rawBlocks} />
			</main>
		</>
	);
}

export const query = graphql`
	query indexQuery {
		page: sanityHomePage {
			_id
			title
			seo {
				...seoFields
			}
			_rawBlocks(resolveReferences: { maxDepth: 10 })
			blocks {
				...blocks
			}
		}
	}
`;
